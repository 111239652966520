.svg {
  padding-top: 40vh;
  padding-left: 15vw;
}

/* .container {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
} */
